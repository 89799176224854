import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET, PATCH, QUERY } from "@/core/services/store/request.module";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      payment: 0,
      pageLoading: false,
      particuleFieldLoading: false,
      paymentDatePicker: false,
      detail: {
        id: 0,
        invoice: {
          id: 0,
        },
        payment_mode: {},
        documents: [],
        custom_field: [],
      },
      customer: {},
      property: {},
      billing: {},
      contact_person: {},
      notesAttachmentData: {},
      paymentModeList: [],
      moreActions: [],
      updateDetailDialog: {
        reference: false,
        payment_mode: false,
        payment_date: false,
        amount: false,
        transaction_id: false,
      },
      updateDetail: {
        reference: null,
        payment_mode: null,
        payment_date: null,
        amount: null,
        transaction_id: null,
      },
    };
  },
  methods: {
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "mark_as_unpaid":
          _this
            .patchPayment({ status: 2 })
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "mark_as_paid":
          _this
            .patchPayment({ status: 3 })
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "download_pdf":
          window.open(
            process.env.VUE_APP_API_URL +
              "payment/" +
              _this.detail.id +
              "/pdf/download",
            "_blank"
          );
          break;
        case "print":
          window.open(
            process.env.VUE_APP_API_URL +
              "payment/" +
              _this.detail.id +
              "/print",
            "_blank"
          );
          break;
      }
    },
    updateField(type) {
      const _this = this;
      if (_this.canUpdatePayment && _this.getPermission("payment:update")) {
        _this.onEscParticularField();
        _this.updateDetailDialog[type] = true;
        if (type == "payment_mode") {
          _this.updateDetail[type] = _this.detail[type].id;
        } else {
          _this.updateDetail[type] = _this.detail[type];
        }
        setTimeout(function () {
          _this.$refs[type].focus();
        }, 50);
      }
    },
    onEscParticularField() {
      this.updateDetailDialog = {
        reference: false,
        payment_mode: false,
        payment_date: false,
        amount: false,
        transaction_id: false,
      };
      this.updateDetail = {
        reference: null,
        payment_mode: null,
        payment_date: null,
        amount: null,
        transaction_id: null,
      };
    },
    getAttributes(attribute) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "payment/options",
          data: { attribute },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.payment_modes) === false) {
            _this.paymentModeList = data.payment_modes;
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateParticularField() {
      const _this = this;
      _this.particuleFieldLoading = true;
      _this.paymentDatePicker = false;
      _this
        .patchPayment(_this.updateDetail)
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.particuleFieldLoading = false;
          _this.onEscParticularField();
        });
    },
    getFormattedDate(date) {
      return this.formatDate(date);
    },
    patchPayment(data) {
      const _this = this;
      if (_this.canUpdatePayment && _this.getPermission("payment:update")) {
        return new Promise((resolve, reject) => {
          _this.$store
            .dispatch(PATCH, { url: "payment/" + _this.detail.id, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    loadContent() {
      const _this = this;
      _this
        .getPayment()
        .then((response) => {
          _this.payment = response.payment.id;
          _this.detail = response.payment;
          _this.moreActions = _this.detail.more_actions;
          _this.customer = response.customer;
          _this.property = response.property;
          _this.billing = response.billing;
          _this.contact_person = response.contact_person;
          _this.notesAttachmentData = {
            id: _this.detail.id,
            admin_remark: _this.detail.admin_note,
            client_remark: _this.detail.client_note,
            documents: _this.detail.documents,
          };
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Payment", route: "payment" },
            { title: "Detail" },
            { title: _this.detail.barcode },
          ]);
        })
        .catch(() => {
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.onEscParticularField();
        });
    },
    getPayment() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "payment/" + _this.payment })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Payment",
        route: "payment",
      },
      {
        title: "Detail",
      },
    ]);
    _this.loadContent();
    _this.getAttributes(["payment_modes"]);
  },
  created() {
    const _this = this;
    _this.payment = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.payment || _this.payment <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    canUpdatePayment() {
      if (this.detail && this.detail.status == 3) {
        return false;
      }
      return this.getPermission("payment:update");
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.property.unit_no) {
        propertyArr.push(this.property.unit_no);
      }
      if (this.property.street_1) {
        propertyArr.push(this.property.street_1);
      }
      if (this.property.street_2) {
        propertyArr.push(this.property.street_2);
      }
      if (this.property.zip_code) {
        propertyArr.push(this.property.zip_code);
      }
      if (this.CityName) {
        propertyArr.push(this.CityName);
      }
      if (this.StateName) {
        propertyArr.push(this.StateName);
      }
      if (this.CountryName) {
        propertyArr.push(this.CountryName);
      }
      return propertyArr.join(", ");
    },
    BillingDetail() {
      let billingArr = new Array();
      if (this.billing.unit_no) {
        billingArr.push(this.billing.unit_no);
      }
      if (this.billing.street_1) {
        billingArr.push(this.billing.street_1);
      }
      if (this.billing.street_2) {
        billingArr.push(this.billing.street_2);
      }
      if (this.billing.zip_code) {
        billingArr.push(this.billing.zip_code);
      }
      if (this.CityBillingName) {
        billingArr.push(this.CityBillingName);
      }
      if (this.StateBillingName) {
        billingArr.push(this.StateBillingName);
      }
      if (this.CountryBillingName) {
        billingArr.push(this.CountryBillingName);
      }
      return billingArr.join(", ");
    },
    CityBillingName() {
      return this.lodash.isEmpty(this.billing.city) === false
        ? this.billing.city.name
        : null;
    },
    StateBillingName() {
      return this.lodash.isEmpty(this.billing.state) === false
        ? this.billing.state.name
        : null;
    },
    CountryBillingName() {
      return this.lodash.isEmpty(this.billing.country) === false
        ? this.billing.country.name
        : null;
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
};
